<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-pb-none">
        <q-btn
          flat
          label="back"
          color="primary"
          @click="$router.go(-1)"
        ></q-btn>
      </q-card-section>
      <q-card-section class="column q-gutter-sm">
        <q-select
          :options="listTingkat"
          v-model="selTingkat"
          outlined
          label="Tingkat"
          @input="getsiswa"
        ></q-select>
      </q-card-section>
      <q-separator></q-separator>
      <q-input
        outlined
        dense
        label="Cari Siswa/NISN"
        v-model="searchTerm"
        @input="getshow"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="min-height:calc(100vh - 315px)"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>nama</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filteredSiswa" :key="i">
            <td>{{ val.nama }}</td>
            <td>
              <q-btn
                flat
                color="primary"
                icon="info"
                @click="
                  selsiswa = val;
                  detail = true;
                "
              ></q-btn>
              <q-btn
                flat
                color="primary"
                icon="add"
                @click="(selsiswa = val), (confirm = true)"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="center">
        <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
          input-class="text-orange-10"
          @input="getshow"
        />
      </q-card-actions>
    </q-card>
    <q-dialog v-model="detail" v-if="selsiswa">
      <q-card>
        <q-markup-table>
          <tr>
            <td>Nama Siswa</td>
            <td>:</td>
            <td>{{ selsiswa.nama }}</td>
          </tr>
          <tr>
            <td>Nama Ayah</td>
            <td>:</td>
            <td>{{ selsiswa.nama_ayah }}</td>
          </tr>
          <tr>
            <td>Nama Ibu</td>
            <td>:</td>
            <td>{{ selsiswa.nama_ibu }}</td>
          </tr>
        </q-markup-table>

        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="confirm" v-if="selsiswa">
      <q-card>
        <q-card-section>
          Apa anda yakin ingin menambahkan {{ selsiswa.nama }} ke List Binaan
          {{ tipe }} anda?
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Tidak" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="Ya"
            color="positive"
            @click="addSiswa"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      detail: false,
      confirm: false,

      listTingkat: [],
      selTingkat: null,
      searchTerm: "",

      user: {},

      listSiswa: [],
      filteredSiswa: [],
      selsiswa: null,

      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
      tipe: "",
    };
  },
  async mounted() {
    if (this.$route.params.tipe == "id_musyrif") {
      this.tipe = "Musyrif";
    }
    if (this.$route.params.tipe == "id_murabbi_tahfidz") {
      this.tipe = "Murabbi tahfidz";
    }
    if (this.$route.params.tipe == "id_murabbi_mentor") {
      this.tipe = "Murabbi mentor";
    }
    await this.getuser();
    await this.gettingkat();
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listSiswa;
      else {
        return this.listSiswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getuser() {
      this.user = await this.getCurrentUser();
    },
    async addSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.put(
        `/pengaturan/bimbingan/addsiswa/${this.$route.params.id}/${this.$route.params.tipe}/${this.selsiswa.id}`
      );
      this.$q.loading.hide();
      await this.getsiswa();
      this.getshow();
    },
    async gettingkat() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengaturan/bimbingan/gettingkat/${localStorage.getItem("jenjang")}`
      );
      let temp = [];
      for (let item of resp.data) {
        temp.push(item.tingkat);
      }
      this.listTingkat = temp;
      this.$q.loading.hide();
    },
    async getsiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengaturan/bimbingan/getsiswaempty/${
          this.$route.params.tipe
        }/${localStorage.getItem("jenjang")}/${this.selTingkat}`
      );
      this.listSiswa = resp.data;
      this.$q.loading.hide();
      this.getshow();
    },
    getshow() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let siswa = JSON.parse(JSON.stringify(this.listSiswa));

      if (this.searchTerm != "") {
        siswa = siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }

      let show = [];
      for (let i = 0; i < 10; i++) {
        if (siswa[(this.pagination.current - 1) * 10 + i]) {
          show.push(siswa[(this.pagination.current - 1) * 10 + i]);
        }
      }
      this.pagination.max = Math.ceil(siswa.length / 10);
      this.filteredSiswa = show;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
